<template>
    <form @submit.prevent="submit" @keydown="form.onKeydown($event)">
        <div class="row">
            <AlertError :form="form" />
            <AlertSuccess :form="form" :message="$t('contact_form_success')" />

            <!-- Begin Text input element -->
            <div class="col-md-12">
                <div class="form-group">
                    <label for="name">
                        {{ $t('name') }} <span>*</span>
                    </label>
                    <input type="text" class="form-control" v-model="form.name" name="name" :placeholder="$t('name_placeholder')">
                    <HasError :form="form" field="name" />
                </div>
            </div>
            <!-- End Text input element -->

            <!-- Begin Text input element -->
            <div class="col-md-6">
                <div class="form-group">
                    <label for="email">
                        {{ $t('email') }} <span>*</span>
                    </label>
                    <input type="text" class="form-control" v-model="form.email" name="email" :placeholder="$t('email_placeholder')">
                    <HasError :form="form" field="email" />
                </div>
            </div>
            <!-- End Text input element -->

            <!-- Begin Text input element -->
            <div class="col-md-6">
                <div class="form-group">
                    <label for="phone">{{ $t('phone') }}</label>
                    <input type="text" class="form-control" v-model="form.phone" name="phone" :placeholder="$t('phone_placeholder')">
                    <HasError :form="form" field="phone" />
                </div>
            </div>
            <!-- End Text input element -->

            <!-- Begin Text input element -->
            <div class="col-md-12">
                <div class="form-group">
                    <label for="subject">
                        {{ $t('subject') }} <span>*</span>
                    </label>
                    <select class="form-control" v-model="form.subject" name="subject">
                        <option value="null">{{ $t('subject_placeholder') }}</option>
                        <option value="product_complaint">{{ $t('contact_form_subject_1') }}</option>
                        <option value="i_want_to_be_part_of_wiese">{{ $t('contact_form_subject_2') }}</option>
                        <option value="i_want_to_be_a_supplier">{{ $t('contact_form_subject_3') }}</option>
                        <option value="where_can_i_buy">{{ $t('contact_form_subject_4') }}</option>
                    </select>
                    <HasError :form="form" field="subject" />
                </div>
            </div>
            <!-- End Text input element -->

            <!-- Begin Textarea element -->
            <div class="col-md-12">
                <div class="form-group">
                    <label for="message">
                       {{ $t('message') }} <span>*</span>
                    </label>
                    <textarea class="form-control" v-model="form.message" name="message" rows="3" :placeholder="$t('message_placeholder')"></textarea>
                    <HasError :form="form" field="message" />
                </div>
            </div>
            <!-- End Textarea element -->

            <!-- Begin Captcha element -->

            <!-- End Captcha element -->

            <!-- Begin Submit button -->
            <div class="col-md-12 mt-3">
                <Button class="btn btn-outline-primary btn-lg fw-bold" :form="form"> {{ $t('send_submit') }}</Button>
            </div>
            <!-- End Submit button -->
        </div>
    </form>
</template>

<script>
import Form from 'vform'
import { getActiveLanguage } from 'laravel-vue-i18n';

export default {
    data: () => ({
        form: new Form({
            name: '',
            email: '',
            phone: '',
            subject: null,
            message: '',
            lang: getActiveLanguage()
        })
    }),

    methods: {
        async submit () {
            const response = await this.form.post('/send-contact-form')
                .then((response) => {
                    if (response.status == 204) {
                        this.form.reset()
                    }
                })
        }
    }
}
</script>
