/*-----------------------------------------------------------------------------------

    Theme Name: Croper - Gardening and Landscaping Template
    Description: Gardening and Landscaping Template
    Author: Chitrakoot Web
    Version: 1.0

    /* ----------------------------------

    JS Active Code Index

        01. Preloader
        02. Sticky Header
        03. Scroll To Top
        04. Parallax
        05. Video
        06. Tab Effect
        07. Wow animation - on scroll
        08. Copy to clipboard
        09. Sliders
        10. Tabs
        11. CountUp
        12. Countdown
        13. Current Year
        14. Portfolio Gallery
        15. Scroll sidebar

    ---------------------------------- */

    // import 'magnific-popup';
    import owlCarousel from 'owl.carousel';
    import { WOW } from 'wowjs';
    import easyResponsiveTabs from 'easy-responsive-tabs';
    import Slick from 'slick-carousel';

    (function($) {

        var $window = $(window);

        /*------------------------------------
            01. Preloader
        --------------------------------------*/

        $('#preloader').fadeOut('normall', function() {
            $(this).remove();
        });

        /*------------------------------------
            02. Sticky Header
        --------------------------------------*/

        $window.on('scroll', function() {
            var scroll = $window.scrollTop();
            var customerlogochange = $('.navbar-brand.customerlogodefault img');
            var customerlogodefault = $('.navbar-brand.customerlogodefault img');
            var dealerlogochange = $('.navbar-brand.dealerlogodefault img');
            var dealerlogodefault = $('.navbar-brand.dealerlogodefault img');

            if (scroll <= 50) {
                $('header').removeClass('scrollHeader').addClass('fixedHeader');
                customerlogochange.attr('src', '/assets/sites/img/customer/header_wiese_premium.png');
                customerlogodefault.attr('src', '/assets/sites/img/customer/header_wiese_premium.png');

                dealerlogochange.attr('src', '/assets/sites/img/dealer/header_wiese.png');
                dealerlogodefault.attr('src', '/assets/sites/img/dealer/header_wiese.png');
            }
            else {
                $('header').removeClass('fixedHeader').addClass('scrollHeader');
                customerlogochange.attr('src', '/assets/sites/img/customer/header_wiese_premium.png');
                customerlogodefault.attr('src', '/assets/sites/img/customer/header_wiese_premium.png');

                dealerlogochange.attr('src', '/assets/sites/img/dealer/header_wiese.png');
                dealerlogodefault.attr('src', '/assets/sites/img/dealer/header_wiese.png');
            }
        });

        /*------------------------------------
            03. Scroll To Top
        --------------------------------------*/

        $window.on('scroll', function() {
            if ($(this).scrollTop() > 500) {
                $('.scroll-to-top').fadeIn(400);

            } else {
                $('.scroll-to-top').fadeOut(400);
            }
        });

        $('.scroll-to-top').on('click', function(event) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: 0
            }, 600);
        });

        $('.scroll-submenu').on('click', function(event) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: document.getElementById(this.hash.slice(1)).offsetTop
            }, 600);
        });

        /*------------------------------------
            04. Parallax
        --------------------------------------*/

        // sections background image from data background
        var pageSection = $('.parallax,.bg-img');
        pageSection.each(function(indx) {

            if ($(this).attr('data-background')) {
                $(this).css('background-image', 'url(' + $(this).data('background') + ')');
            }
        });

        /*------------------------------------
            05. Video
        --------------------------------------*/
        /*
        // It is for local video
        $('.story-video').magnificPopup({
            delegate: '.video',
            type: 'iframe'
        });

        $('.source-modal').magnificPopup({
            type: 'inline',
            mainClass: 'mfp-fade',
            removalDelay: 160
        });
        */

        /*------------------------------------
            06. Tab Effect
        --------------------------------------*/

        // Click on first li element
        $('.tab1').on('click', function() {
            $('.second, .third, .fourth, .fifth').fadeOut();
            $('.first').fadeIn(1000);
         });

        // Click on second li element
        $('.tab2').on('click', function() {
            $('.first, .third, .fourth, .fifth').fadeOut();
            $('.second').fadeIn(1000);
        });

        // Click on third li element
        $('.tab3').on('click', function() {
            $('.first, .second, .fourth, .fifth').fadeOut();
            $('.third').fadeIn(1000);
        });

        // Click on fourth li element
        $('.tab4').on('click', function() {
            $('.first, .second, .third, .fifth').fadeOut();
            $('.fourth').fadeIn(1000);
        });

        // Click on fifth li element
        $('.tab5').on('click', function() {
            $('.first, .second, .third, .fourth').fadeOut();
            $('.fifth').fadeIn(1000);
        });

        /*------------------------------------
            07. Wow animation - on scroll
        --------------------------------------*/

        const wow = new WOW({
            boxClass: 'wow', // default
            animateClass: 'animated', // default
            offset: 0, // default
            mobile: false, // default
            live: false // default
        })

        wow.init();

        /*------------------------------------
            08. Copy to clipboard
        --------------------------------------*/


        /*------------------------------------
            09. Sliders
        --------------------------------------*/

        // === when document ready === //
        $(function() {
            $('.owl-carousel-products').owlCarousel({
                items: 1,
                loop: true,
                dots: true,
                margin: 0,
                autoplay: true,
                smartSpeed: 500,
                autoplayTimeout: 10000,
                responsive: {
                    0: {
                        items: 1
                    },
                    576: {
                        items: 2
                    },
                    992: {
                        items: 3
                    },
                    1200: {
                        items: 3
                    }
                },
            });

            $('.owl-carousel-logos').owlCarousel({
                items: 4,
                loop:true,
                dots: true,
                margin: 0,
                autoplay:true,
                smartSpeed:500,
                autoplayTimeout: 10000,
                responsive: {
                    0: {
                        items: 1
                    },
                    576: {
                        items: 4
                    },
                    992: {
                        items: 5
                    },
                    1200: {
                        items: 5
                    }
                },
            });

            // Product detail owlCarousel
            $('#owl-carousel-product-detail').owlCarousel({
                items: 1,
                loop: false,
                dots: true,
                margin: 0,
                autoplay: false,
                smartSpeed: 500,
                lazyLoad:true,
                responsiveClass: true,
                nav: true,
                navText: ["<i class='fa fa-angle-left text-primary'></i>", "<i class='fa fa-angle-right text-primary'></i>"],
            });

            // Default owlCarousel
            $('.owl-carousel').owlCarousel({
                items: 1,
                loop: true,
                dots: true,
                margin: 0,
                autoplay: true,
                smartSpeed: 500,
                video: true,
                lazyLoad: true,
                responsiveClass: true,
                autoplayTimeout: 10000,
                nav: true,
                navText: ["<i class='fa fa-angle-left text-primary'></i>", "<i class='fa fa-angle-right text-primary'></i>"],
            });

            // Slider text animation
            var owl = $('.slider-fade1');
            owl.on('changed.owl.carousel', function(event) {
                var item = event.item.index - 2;     // Position of the current item
                $('.h5').removeClass('animated fadeInUp');
                $('.title').removeClass('animated fadeInUp');
                $('p').removeClass('animated fadeInUp');
                $('a').removeClass('animated fadeInUp');
                $('.owl-item').not('.cloned').eq(item).find('.h5').addClass('animated fadeInUp');
                $('.owl-item').not('.cloned').eq(item).find('.title').addClass('animated fadeInUp');
                $('.owl-item').not('.cloned').eq(item).find('p').addClass('animated fadeInUp');
                $('.owl-item').not('.cloned').eq(item).find('a').addClass('animated fadeInUp');
            });


            /*------------------------------------
                10. Tabs
            --------------------------------------*/

            //Horizontal Tab
            if ($('.horizontaltab').length !== 0) {
                $('.horizontaltab').easyResponsiveTabs({
                    type: 'default', //Types: default, vertical, accordion
                    width: 'auto', //auto or any width like 600px
                    fit: true, // 100% fit in a container
                    tabidentify: 'hor_1', // The tab groups identifier
                    activate: function(event) { // Callback function if tab is switched
                        var $tab = $(this);
                        var $info = $('#nested-tabInfo');
                        var $name = $('span', $info);
                        $name.text($tab.text());
                        $info.show();
                    }
                });
            }

            /*------------------------------------
                13. Current Year
            --------------------------------------*/

            $('.current-year').text(new Date().getFullYear());
        });

        /*------------------------------------
            15. Scroll sidebar
        --------------------------------------*/

        var sticky = $('#nav-product-menu').offset();
        var container = $('#listProducts').height();

        window.addEventListener('scroll', event => {
            let fromTop = window.scrollY;

            // if (fromTop >= sticky.top && fromTop <= container) {
            if (fromTop >= sticky.top) {
                $('#nav-product-menu').addClass('sticky');
            } else {
                $('#nav-product-menu').removeClass('sticky');
            }
        });

    })(jQuery);



