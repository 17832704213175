require('./site/bootstrap')
require('./site/nav-menu')
require('./site/main-site')

import { createApp } from 'vue'
import { i18nVue } from 'laravel-vue-i18n'
import {
    Button,
    HasError,
    AlertError,
    AlertErrors,
    AlertSuccess
} from 'vform/src/components/bootstrap5'
import ContactForm from './site/components/ContactForm.vue'

createApp()
    .component(Button.name, Button)
    .component(HasError.name, HasError)
    .component(AlertError.name, AlertError)
    .component(AlertErrors.name, AlertErrors)
    .component(AlertSuccess.name, AlertSuccess)
    .component('ContactForm', ContactForm)
    .use(i18nVue, {
        resolve: lang => import(`../../lang/${lang}.json`),
    })
    .mount('#app')
